<template>
  <div class="container-sm">
    <br><br><br><br><br>
    <router-link
      :to="{ name: 'home' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center mr-1">
          <i class="fas fa-long-arrow-left" />
        </div>
        <h5 class="text-center font-weight-bolder m-0">
          โปรโมชั่น
        </h5>
      </div>
    </router-link>

    <div class="card p-1 mt-1">
      <div class="text-center header-dark">
        <h5
          class="font-weight-bolder m-0"
        >
          เลือกรับโปรโมชั่น
        </h5>
      </div>

      <div class="row">
        <div
          v-for="item in prolist"
          :key="item.id"
          class="col-12 mt-1"
        >
          <div class="card-list">
            <div class="justify-content-between d-flex">
              <b-img
                :src="item.pic"
                height="100"
                class="img_pro"
              />
              <div class="promo">
                <h5 style="color: #fff; font-size: 0.9rem">
                  {{ item.name_show }}
                </h5>
                <small style="margin-top: -10px; color: #bfbfbf; font-size: 0.8rem">
                  {{ item.detail_show }}
                </small><br>

                <a
                  v-if="item.promotion_id == 1"
                  style="margin-top: -10px; color: #FF8700; font-size: 0.8rem"
                  @click="showModal1"
                >เพิ่มเติม...</a>

                <a
                  v-if="item.promotion_id == 6"
                  style="margin-top: -10px; color: #FF8700; font-size: 0.8rem"
                  @click="showModal2"
                >เพิ่มเติม...</a>
              </div>
            </div>

          </div>

          <!-- <div class="text-center">
            <small
              class="text-warning"
              style="font-size: 0.8rem"
            >{{ item.remark }}</small>
          </div> -->
          <div class="demo-inline-spacing mt-1">
            <button
              class="m-0 btn btn-block router-link-active btn-theme-two"
              @click="getbetflixpro(item.promotion_id)"
            >
              รับโบนัส
            </button>

          </div>
        </div>
      </div>
      <b-modal
        ref="my-modal1"
        hide-footer
        centered
        hide-header
      >
        <div
          style="color: #000"
          class="p-1 bg-whites"
        >
          <h4
            style="color: #000"
            class="text-center"
          >
            💌 เงื่อนไขการรับโปรแนะนำเพื่อน 💌
          </h4>
          <hr>
          <p>1. User ที่แนะนำเพื่อนมาสมัคร ต้องCopyลิ้งแนะนำของตนเองส่งให้ผู้ที่จะแนะนำ</p>
          <p>2. User ที่แนะนำเพื่อนมาสมัครจะต้องไม่ใช่ User ที่ถูกแนะนำมาจะต้องสมัครผ่านลิ้งสมัครมาเท่านั้น</p>
          <!-- <p>3. เพื่อนที่ถูกแนะนำมาสมัครสมาชิกจะฝากเงินรับโปรโมชั่นสมัครสมาชิกใหม่ 50%</p> -->
          <p>3. เพื่อนที่ถูกแนะนำมาสมัคร ต้องฝากขั้นต่ำ 200 บาท ขึ้นไป</p>
          <p>User ที่แนะนำจะได้ 10% ของยอดฝากจาก User ที่ถูกแนะนำมา</p>
          <p>4. ทำเทิร์น 3 เท่าจากโบนัสที่ได้รับ โบนัสสูงสุด 100 บาท
            ถอนได้สูงสุด 10 เท่า</p>
          <!-- <p>6. เพื่อนที่ถูกแนะนำจะต้องรับโปรโมชั่น สมาชิกใหม่ 50%(ฝาก 100 รับ 150)</p> -->
          <hr>
          <p class="text-center text-warning">
            ⚠️ หากไม่ตรงตามเงื่อนไขที่แจ้งไปข้างต้นจะไม่ได้รับโบนัสแนะนำเพื่อน ⚠️
          </p>

          <button
            class="btn btn-block btn-gradient-primary"
            @click="toggleModal1"
          >
            ตกลง
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="my-modal2"
        hide-footer
        centered
        hide-header
      >
        <div
          style="color: #000"
          class="p-1 bg-whites"
        >
          <h4
            style="color: #000"
            class="text-center"
          >
            ⭐️ คืนยอดเสียรายวัน 10 % ⭐️
          </h4>
          <hr>
          <h5 style="color: #000">
            เงื่อนไขระบบจะนับเวลานับโปรตั้งแต่เวลา 23.00 น. ของวันก่อนหน้า - 22.59 น. ของวันปัจจุบัน
          </h5>
          <p>✅กดรับได้แค่ 1 ครั้ง/ต่อวัน หลังจากกดรับยอดเสียของวันนั้นจะไม่สามารถรับได้อีก
            กดก่อนเวลา 22.59 เท่านั้นค่ะ  หลัง 23.00 จะรีเซ็ตนับ 0 ใหม่ทุกวัน และยอดจะไม่สะสมไปวันถัดไปค่ะ</p>
          <p>✅ต้องไม่มีรายการถอน (ทั้งวัน)</p>
          <p>✅ต้องไม่มีรายรับโบนัส (ทั้งวัน)</p>
          <p>✅ต้องมีเครดิตคงน้อยกว่าหรือเท่ากับ 5 บาท</p>
          <p>✅ต้องไม่มีเดิมพันค้าง</p>
          <p class="m-0">
            ตัวอย่างเช่น
          </p>

          <small>
            ⚠️ ลูกค้าที่กดรับยอดเสีย และเติมเงินเข้ามาทบกับยอดเสียนั้น ทางเราจะนับเป็นยอดของยอดเสีย ⚠️<br>
            <u>ตัวอย่างเช่น</u><br>
            ⭐️กดรับยอดเสียไป500 ฝากมาทบ2000<br>
            ⭐️หากนำไปเล่นได้ ทางเราจะยึดเป็นเงื่อนไขของโปรคืนยอดเสียเท่านั้นค่ะ<br>
            ⭐️และจะถอนได้สูงสุดที่ 3000 บาท เท่านั้นนะคะ
          </small>
          <hr>
          <p class="text-center text-warning">
            ⚠️ รบกวนเล่นยอดเสียให้หมดและถอนออกมาให้หมดก่อน
            แล้วฝากเงินเข้ามาหลังจากที่ยอดเสียหมดไปแล้ว เพราะทางเราจะยึดจากโปรคืนยอดเสีย เป็นหลักเท่านั้น ⚠️
          </p>

          <button
            class="btn btn-block btn-gradient-primary"
            @click="toggleModal2"
          >
            ตกลง
          </button>
        </div>
      </b-modal>
      <br><br><br>
    </div>
  </div>
</template>

<script>
import { BImg, BModal } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BModal,
  },
  props: {
    // eslint-disable-next-line
    UserProfile: Object,
  },
  data() {
    return {
      show: true,
      selected: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      prolist: [],
    }
  },
  mounted() {
    this.getUser(this.userData)
    this.getProlist()
  },
  methods: {
    getUser(userData) {
      this.show = true
      this.$http
        .get(`/users/show/${userData.userID}`)
        .then(response => {
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    getbetflixpro(id) {
      if (id === 1) {
        this.$router.push({ name: 'invite-friend' })
      }
      this.show = true
      this.$http
        .get(`/promotion/getbetflixpro/${id}`)
        .then(response => {
          this.show = false
          this.Success(response.data.message)
        })
        .catch(
          error => this.SwalError(error.response.data.message),
          (this.show = false),
        )
    },
    getProlist() {
      this.show = true
      this.$http
        .get('/promotion/prolist')
        .then(response => {
          this.show = false
          this.prolist = response.data
          // console.log(response.data)
        })
        .catch(
          error => this.SwalError(error.response.data.message),
          (this.show = false),
        )
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    // aa aa
    showModal1() {
      this.$refs['my-modal1'].show()
    },
    showModal2() {
      this.$refs['my-modal2'].show()
    },
    toggleModal1() {
      this.$refs['my-modal1'].hide('#toggle-btn')
    },
    toggleModal2() {
      this.$refs['my-modal2'].hide('#toggle-btn')
    },

  },
}
</script>

<style scoped>
  .container-sm {
    max-width: 500px;
  }
  .promo {
    padding: 2px 10px;
    border-radius: 10px;
    width: 100%;
  }
.header-dark {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  padding: 10px;
  border-radius: 10px;
}
.card-list {
  width: 100%;
  background: #2e2e2e;
  box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 10px;
  /* cursor: pointer; */
}

.card {
  border-radius: 14px;
  border: 1px solid rgb(51, 51, 51);
  background: #232323 !important;
  color: rgb(255, 255, 255) !important;
}
.text-yellow {
  color: #ffc107 !important;
}
.bg-balance {
  background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg) !important;
  background-repeat: repeat !important;
  background-size: 100% auto !important;
}
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #fff !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #fff;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.theme-ltobet .book_bank_content .bank-icon .media-icon {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 4px;
  position: relative;
}
.btn-orange {
  background-image: linear-gradient(103deg, #0c2574 1%, #341082);
  width: 100%;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.box-ac-items {
  padding: 10px;
  border-radius: 5px;
  border: #000 1px solid;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

</style>
